import React from "react"

export default function ContactBlock2() {
  return (

    <section className="section contact-block">
      <div className="content-width-m-">
        <div className="h3">Contact Us</div>
        <p className="text-large">Get in touch...</p>
        <div className="contact-info">
          <div className="flex-grid">
            <div className="col-xs"><a href="tel:+12363049890">+1 [ 236 ] 304 9890</a></div>
            <div className="col-xs"><a href="mailto:info@above90lighting.com">info@above90lighting.com</a></div>
          </div>
        </div>
      </div>
    </section>

  )
}