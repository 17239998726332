import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import PageTransition from 'gatsby-v2-plugin-page-transitions'
import { Helmet } from "react-helmet"
import {isMobile} from 'react-device-detect'

export default function Error404() {
  return (

  	<PageTransition
      transitionStyles={{
      entering: { opacity: 0 },
      entered: { opacity: 1 },
      exiting: { opacity: 1 },
      exited: { opacity: 0 },
      }}
    >
      <div className={isMobile ? `wrapper mobile` : `wrapper`}>

        <Helmet>
          <meta charSet="utf-8" />
          <title>404 - Above 90 Lighting</title>
        </Helmet>

        <Header />

        <div className="main">
          <div className="container">

            <section className="section">
              <div className="content-width-s">
                <h1 className="h3">404</h1>
                <p className="text-large">The page you're looking for cannot be found.</p>
              </div>
            </section>

          </div>
        </div>

        <Footer />

      </div>
    </PageTransition>

  )
}
