import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import ContactBlock from "../components/contact-block-2"
import PageTransition from 'gatsby-v2-plugin-page-transitions'
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import {isMobile} from 'react-device-detect'

class Brands extends React.Component {

  render() {
    const nodes = this.props.data.allGoogleSpreadsheetSheet1.edges;

    return (

      <PageTransition
      transitionStyles={{
      entering: { opacity: 0 },
      entered: { opacity: 1 },
      exiting: { opacity: 1 },
      exited: { opacity: 0 },
      }}
    >
        <div className={isMobile ? `wrapper mobile` : `wrapper`}>

          <Helmet>
            <meta charSet="utf-8" />
            <title>Brands - Above 90 Lighting</title>
            <meta name="description" content="Above 90 Lighting collaborates with like-minded Architects, Interiors Designers and Lighting Designers to create beautiful and meaningful spaces." />
            <link rel="canonical" href="https://above90lighting.com/brands" />
          </Helmet>

          <Header />

          <div className="main">
            <div className="container">

              <section className="page-header text-header">
                <div className="content-width-m">
                  <h1>Brands we proudly supply</h1>
                  <a href="/files/Above90_LineCard.pdf" download>Line Card</a>
                </div>
              </section>

              <section className="brands-list">
                <div className="content-width-xxl">
                  <div className="flex-grid">
                    {nodes.map((row, i) => (
                        <div className="col-6 col-sm-4 col-lg-3" key={`${row.node.brandname} ${i}`}>
                          <div className="first-letter">{row.node.letter}</div>
                          <h2 className="text-medium">{row.node.brandname}</h2>
                          <div className="catalogue-link">
                            {row.node.cataloguelink ? <a href={row.node.cataloguelink} download>Catalogue</a> : null }
                          </div>
                          <div className="country">{row.node.country}</div>
                          <div className="url">{row.node.url ? <a href={row.node.url} target="_blank" rel="noreferrer">{row.node.urltext}</a> : null }</div>
                        </div>
                      ))}
                  </div>
                </div>
              </section>

              <ContactBlock />
            </div>
          </div>

          <Footer />

        </div>
      </PageTransition>

    )
  }
}

export default Brands

export const query = graphql`
  query {
    allGoogleSpreadsheetSheet1 {
      edges {
        node {
          letter
          brandname
          cataloguelink
          country
          urltext
          url
        }
      }
    }
  }
`
