import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import PageTransition from 'gatsby-v2-plugin-page-transitions'
import { Helmet } from "react-helmet"
import {isMobile} from 'react-device-detect'

export default function Contact() {
  return (

  	<PageTransition
      transitionStyles={{
      entering: { opacity: 0 },
      entered: { opacity: 1 },
      exiting: { opacity: 1 },
      exited: { opacity: 0 },
      }}
    >
      <div className={isMobile ? `wrapper mobile` : `wrapper`}>

        <Helmet>
          <meta charSet="utf-8" />
          <title>Contact - Above 90 Lighting</title>
          <meta name="description" content="Above 90 Lighting collaborates with like-minded Architects, Interiors Designers and Lighting Designers to create beautiful and meaningful spaces." />
          <link rel="canonical" href="https://above90lighting.com/contact" />
        </Helmet>

        <Header />

        <div className="main">
          <div className="container">

            <section className="section contact">
              <div className="content-width-l">
                <h1 className="h3">Contact Us</h1>
              </div>
              <div className="flex-grid">
              	<div>
              	  <p class="h3">#180-411 Railway Street<br />Vancouver, BC<br />V6A 1A7</p>
              	  <div className="contact-info">
      			        <div className="flex-grid">
      			          <div className="col-xxs"><a href="tel:+12363049890">+1 [ 236 ] 304 9890</a></div>
      			          <div className="col-xxs"><a href="mailto:info@above90lighting.com">info@above90lighting.com</a></div>
      			        </div>
      			      </div>
              	</div>
              	<div>
              	  <figure>
                    <img src="/img/contact.jpg" alt="" />
                  </figure>
              	</div>
              </div>
            </section>

          </div>
        </div>

        <Footer />

      </div>
    </PageTransition>

  )
}
