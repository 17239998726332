import React from "react"
import Logo from "../../static/img/svg/logo.svg"
import { Link } from "gatsby"
import Cursor from '../components/cursor'

export default function Footer() {
  return (

    <footer>
      <div className="container">
        <div className="flex-grid">
          <div>
            <div className="social-links">
              <ul>
                <li><Link to="/newsletter">Newsletter</Link></li>
                <li><a href="https://www.instagram.com/above90lighting/?hl=en" rel="noreferrer" target="_blank">Instagram</a></li>
                <li><a href="https://www.pinterest.ca/Above90Lighting/" rel="noreferrer" target="_blank">Pinterest</a></li>
              </ul>
            </div>
            <div className="copyright">&copy;2021 Above 90 Lighting</div>
          </div>
          <div>
            <Link className="logo logo-footer" to="/"><Logo /></Link>
          </div>
        </div>
      </div>
      <Cursor />
    </footer>

  )
}
