import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import PageTransition from 'gatsby-v2-plugin-page-transitions'
import { Helmet } from "react-helmet"
import SubscribeForm from "../components/mailchimp-form"
import {isMobile} from 'react-device-detect'

export default function Newsletter() {
  return (

  	<PageTransition
      transitionStyles={{
      entering: { opacity: 0 },
      entered: { opacity: 1 },
      exiting: { opacity: 1 },
      exited: { opacity: 0 },
      }}
    >
      <div className={isMobile ? `wrapper mobile` : `wrapper`}>

        <Helmet>
          <meta charSet="utf-8" />
          <title>Newsletter - Above 90 Lighting</title>
          <meta name="description" content="Above 90 Lighting collaborates with like-minded Architects, Interiors Designers and Lighting Designers to create beautiful and meaningful spaces." />
          <link rel="canonical" href="https://above90lighting.com/newsletter" />
        </Helmet>

        <Header />

        <div className="main">
          <div className="container">

            <section className="section">
              <div className="content-width-xs">
                <h1 className="h3">Sign Up</h1>
                <p className="text-large">Subscribe to the Above 90 newsletter to periodically receive product updates, educational seminars and events happening within the lighting community.</p>
                <SubscribeForm />
              </div>
            </section>

          </div>
        </div>

        <Footer />

      </div>
    </PageTransition>

  )
}
