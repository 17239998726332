import React from "react"
import Logo from "../../static/img/svg/logo.svg"
import { Link } from "gatsby"

class Header extends React.Component {

  state = { showMenu: false }

  toggleMenu = () => {
    this.setState({
      showMenu: !this.state.showMenu
    })
  }
 
  render() {
    const menuActive = this.state.showMenu ? 'open' : '';

    return (
      <header>
        <div className="container">
          <div className="flex-grid">
            <div>
              <div className={`nav-block ${menuActive}`}>
                <ul>
                  <li><Link to="/">Home</Link></li>
                  <li><Link to="/brands">Brands</Link></li>
                  <li><Link to="/contact">Contact</Link></li>
                </ul>
              </div>
              <button id="menu-toggle" className={`menu-toggle ${menuActive}`} onClick={this.toggleMenu}>
                <span className="icon"></span>
                <span className="menu-toggle-text" id="menu-toggle-text">Menu</span>
              </button>
            </div>
            <div>
              <Link className="logo" to="/"><Logo /></Link>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default Header;
