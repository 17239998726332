import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import ContactBlock from "../components/contact-block"
import PageTransition from 'gatsby-v2-plugin-page-transitions'
import { Helmet } from "react-helmet"
import {isMobile} from 'react-device-detect'

export default function Home() {
  return (

    <PageTransition
    transitionStyles={{
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 1 },
    exited: { opacity: 0 },
    }}
  >
      <div className={isMobile ? `wrapper mobile` : `wrapper`}>

        <Helmet>
          <meta charSet="utf-8" />
          <title>Above 90 Lighting</title>
          <meta name="description" content="Above 90 Lighting collaborates with like-minded Architects, Interiors Designers and Lighting Designers to create beautiful and meaningful spaces." />
          <link rel="canonical" href="https://above90lighting.com" />
        </Helmet>

        <Header menuActive="" />

        <div className="main">
          <div className="container">

            <section className="page-header">
              <div className="content-width-xl">
                <h1>We represent a collection of high-design lighting brands designed with innovation, quality and purpose.</h1>
              </div>
              <figure>
              	<img src="/img/a90-image-home-1.jpg" alt="" />
              </figure>
            </section>

            <section className="section featured-content">
              <div className="content-width-xl">
                <div className="h3">About</div>
              </div>
              <div className="flex-grid">
                <div className="col-md-8">
                  <div className="content-width-xl">
                    <p className="text-large">Above 90 Lighting collaborates with like-minded Architects, Interiors Designers and Lighting Designers to create beautiful and meaningful spaces. Our attention from design specification through to procurement keeps our projects focused on time and budget.</p>
                  </div>
                </div>
                <div className="col-8 col-md-6 offset-4 offset-md-6">
                  <figure data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                    <img src="/img/a90-image-home-2.jpg" alt="" />
                  </figure>
                </div>
                <div className="col-11 col-md-9">
                  <figure data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                    <img src="/img/a90-image-home-1.1.jpg" alt="" />
                  </figure>
                </div>
                <div className="col-md-8 offset-md-4">
                  <div className="content-width-l float-md-right">
                    <p>Our intentions are driven by each of our brands and specifiers by creating indoor environments that evoke emotion and consider the well being of the occupants. Being conscious of the products we represent we uphold environmental integrity in every project that we are a part of. We are committed to connecting trades with continued education and platforms where we can share information, foster ideas and support our design community.</p>
                  </div>
                </div>
              </div>
              <figure data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease" className="featured-img">
                <img src="/img/a90-image-home-1.2.jpg" alt="" />
              </figure>
            </section>

            <section className="section portfolio">
              <div className="content-width-ms">
                <div className="h3">Portfolio</div>
                <h2>Our Collaborations</h2>
                <p>For many years we have been supporting the Lighting Designers and A+D community working together to create forward-thinking and purposeful interior spaces.</p>
              </div>
              <div className="flex-grid">
                <div className="col-md-7">
                  <figure data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                    <img src="/img/a90-work-samples-1.jpg" alt="" />
                    <figcaption><h3>BlueArck Private Equity by Edit Studios<br /> Manufacturers Flos, Luceplan, Modular Lighting Instruments by MLINA, RBW & Serge Mouille</h3></figcaption>
                  </figure>
                </div>
                <div className="col-md-5">
                  <figure data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                    <img src="/img/a90-work-samples-3.jpg" alt="" />
                    <figcaption><h3>Heirloom by Evoke International Design<br /> Manufacturers Andlight & Flos</h3></figcaption>
                  </figure>
                </div>
                <div className="col-md-5">
                  <figure data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                    <img src="/img/a90-work-samples-6.jpg" alt="" />
                    <figcaption><h3>Wickaninnish Inn by Domain Creative Design Services Ltd.<br /> Manufacturers Bocci</h3></figcaption>
                  </figure>
                </div>
                <div className="col-md-7">
                  <figure data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                    <img src="/img/a90-work-samples-4.jpg" alt="" />
                    <figcaption><h3>Hudson Pacific Office by McKinley Studios<br /> Manufacturers Andlight, Flos, Lambert & Fils</h3></figcaption>
                  </figure>
                </div>
                <div className="col-md-7">
                  <figure data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                    <img src="/img/a90-work-samples-2.jpg" alt="" />
                    <figcaption><h3>DHX by Evoke International Design<br /> Manufacturers Andlight, Buzzispace, Gubi & Muuto</h3></figcaption>
                  </figure>
                </div>
                <div className="col-md-5">
                  <figure data-sal="slide-up" data-sal-duration="1000" data-sal-easing="ease">
                    <img src="/img/a90-work-samples-5.jpg" alt="" />
                    <figcaption><h3>UBC Sports Medicine & Exercise Science Facility by HCMA<br /> Architecture & Design and AES Engineering</h3></figcaption>
                  </figure>
                </div>
              </div>
            </section>

            <section className="section logos">
              <div className="content-width-xs">
                <div className="h3">Featured Clients</div>
                <h2>Supporting our local industry professionals</h2>
                <p>Encouraging the Integrated Design Process with transparency throughout the project’s life cycle to achieve the project’s intent, schedule and budget.</p>
              </div>
              <div className="flex-grid">
                <div className="col-6 col-sm-4">
                  <figure>
                    <img src="/img/svg/logo-1-mckinley-01.svg" alt="" />
                  </figure>
                </div>
                <div className="col-6 col-sm-4">
                  <figure>
                    <img src="/img/svg/logo-2-edit.svg" alt="" />
                  </figure>
                </div>
                <div className="col-6 col-sm-4">
                  <figure>
                    <img src="/img/svg/logo-3-dialog.svg" alt="" />
                  </figure>
                </div>
                <div className="col-6 col-sm-4">
                  <figure>
                    <img src="/img/svg/logo-4-aes-01.svg" alt="" />
                  </figure>
                </div>
                <div className="col-6 col-sm-4">
                  <figure>
                    <img src="/img/svg/logo-5-evoke.svg" alt="" />
                  </figure>
                </div>
                <div className="col-6 col-sm-4">
                  <figure>
                    <img src="/img/svg/logo-6-etro.svg" alt="" />
                  </figure>
                </div>
                <div className="col-6 col-sm-4">
                  <figure>
                    <img src="/img/svg/logo-7-BYUD.svg" alt="" />
                  </figure>
                </div>
                <div className="col-6 col-sm-4">
                  <figure>
                    <img src="/img/svg/logo-8-linden.svg" alt="" />
                  </figure>
                </div>
                <div className="col-6 col-sm-4">
                  <figure>
                    <img src="/img/svg/logo-9-boffa.svg" alt="" />
                  </figure>
                </div>
              </div>
            </section>

            <ContactBlock />
          </div>
        </div>

        <Footer />

      </div>
    </PageTransition>

  )
}
