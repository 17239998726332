import React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

export default class SubscribeForm extends React.Component {

  state = {
    email: '',
    message: '',
    type: '',
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const result = await addToMailchimp(this.state.email);
    this.setState({ message: result.msg });
    this.setState({ type: result.result });
  };

  render() {
    return (
      <div id="mc_embed_signup" class="mc_embed_signup">
        <form name="subscribeForm" method="POST" id="subscribe-form" className="subscribe-form" onSubmit={this.handleSubmit}>
          <div class="mc-field-group">
              <label htmlFor="mce-EMAIL" className="h3">Email *</label>
              <input
                id="mce-EMAIL"
                className="h3 subscribe-email"
                type="text"
                name="email"
                placeholder="Enter your email address"
                value={this.state.email}
                onChange={this.handleInputChange}
              />
              <div className="validate-msg" data-type={ this.state.type } dangerouslySetInnerHTML={{ __html: this.state.message }} />
          </div>
          <button className="h3 btn" type="submit">Subscribe</button>
        </form>
      </div>
    );
  }
}